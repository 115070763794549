<template>
  <div class="patch_note card">
    <div class="note_wrap">
      <button class="title_btn" type="button">
        <h4
          class="subject"
          @click="$emit('popPatchNote')"
          :title="`${newPatchNoteList[0].title}`"
        >
          {{ `&#62; ${newPatchNoteList[0].title} ` }}<span>*</span>
        </h4>
      </button>
      <div>
        <span>{{ newPatchNoteList[0].create_time | formatDateNoHours }}</span>
        <button class="more_btn" @click="PatchNoteMoreModalOpen = true">
          전체보기
        </button>
      </div>
    </div>
    <patch-note-more-modal
      v-if="PatchNoteMoreModalOpen"
      @onclose="PatchNoteMoreModalOpen = false"
    ></patch-note-more-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import FavoriteMixin from '@/mixins/favorite';
import PatchNoteMoreModal from '@/layouts/components/PatchNoteMoreModal.vue';

export default {
  mixins: [ModalMixin, SpinnerMixin, FavoriteMixin],
  components: {
    PatchNoteMoreModal,
  },
  data() {
    return {
      PatchNoteMoreModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      newPatchNoteList: 'getPatchNoteOnlyListFromPatchNote',
      systemCompany: 'getSystemCompany',
    }),
  },
  methods: {},
};
</script>

<style scoped></style>
