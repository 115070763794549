<template>
  <transition appear name="fade">
    <div class="calendar">
      <div class="calendar_description">
        <span
          v-for="option in filterOptions"
          :key="option.id"
          :class="`select_${option.id}`"
          >{{ option.name }}</span
        >
      </div>
      <div id="calendar"></div>
      <calendar-modal
        :dateString="this.dateString"
        :dateString2="this.dateString2"
        :filterData="filterData"
        @rerendering="closeModal"
        v-if="isCalendarModalOpen"
        @onclose="closeModal"
      ></calendar-modal>
      <div
        class="modal_layer"
        v-if="isCalendarModalOpen"
        @click="closeModal"
      ></div>
    </div>
  </transition>
</template>

<script>
import { Calendar } from '@fullcalendar/core';
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import dayGridPlugin from '@fullcalendar/daygrid';
import InteractionPlugin from '@fullcalendar/interaction';
import MODAL_MIXIN from '@/mixins/modal';
import FETCH_MIXIN from '@/mixins/fetch';
import CalendarModal from '@/layouts/components/CalendarModal';
import FavoriteMixin from '@/mixins/favorite';

export default {
  mixins: [MODAL_MIXIN, FETCH_MIXIN, FavoriteMixin],
  components: {
    CalendarModal,
  },
  data() {
    return {
      calendar: null,
      isCalendarModalOpen: false,
      dateString: '',
      dateString2: '',
      date: null,
      rendering: 0,
    };
  },
  computed: {
    ...mapGetters({
      users: 'getUsersFromUserPage',
      userId: 'getUserId',
      user_role_type: 'getUserRoleTypeFromUserPage',
      monthData: 'getCalendarMonthFromMainCalendar',
      calendarOptions: 'getCalendarOptionsFromMainCalendar',
      month: 'getMonthFromMainCalendar',
      year: 'getYearFromMainCalendar',
      holiday: 'getHolidayFromMainCalendar',
    }),
    filterData() {
      if (this.monthData !== undefined && this.monthData.length > 0) {
        let clone = this.lodash.clonedeep(this.monthData);
        if (this.calendarOptions !== undefined) {
          let cloneOpt = this.lodash
            .clonedeep(this.calendarOptions)
            .filter(x => x.setting == true)
            .map(x => x.id);
          let checkedTypeId = clone.filter(x =>
            cloneOpt.includes(x.system_event_type_id),
          );
          checkedTypeId.forEach((el, index) => {
            el.start = checkedTypeId[index].create_time
              .replace(/ /gi, 'T')
              .substr(0, 19);
            el.extendedProps = {
              status: checkedTypeId[index].system_event_type_id2,
            };
          });
          return checkedTypeId;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    filterOptions() {
      if (this.filterData !== undefined && this.filterData.length > 0) {
        let clone = this.lodash
          .clonedeep(this.filterData)
          .map(x => x.system_event_type_id);
        if (this.calendarOptions !== undefined) {
          let cloneOpt = this.lodash.clonedeep(this.calendarOptions);
          let checkedDate = cloneOpt.filter(x => clone.includes(x.id));
          return checkedDate;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
  },
  methods: {
    async fetchUserList() {
      try {
        this.showSpinner();
        console.log(4);
        await this.$store
          .dispatch('FETCH_USER_LIST')
          .then(() => {})
          .catch(() => {
            this.openOneButtonModal(
              '불러오기 오류',
              '사용자 정보를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
            );
          });
        this.hideSpinner();
      } catch (error) {
        this.openOneButtonModal(
          '불러오기 오류',
          '사용자 정보를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
        );
        this.hideSpinner();
      }
    },
    openModal() {
      this.isCalendarModalOpen = true;
      this.rendering--;
    },
    async closeModal() {
      this.isCalendarModalOpen = false;
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_MAIN_CALENDAR_MONTH',
        `${this.year}-${this.month}`,
        '일정',
      );
      this.calendar.batchRendering(() => {
        // remove all events
        this.calendar.getEvents().forEach(event => event.remove());
        // add your new events source
        this.calendar.addEventSource(this.filterData);
      });
    },
  },
  async mounted() {
    if (this.users.length == 0) {
      await this.fetchUserList();
    }
    if (this.calendarOptions.length == 0) {
      const findId = this.users.find(x => x.account == this.userId).id;
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_MAIN_CALENDAR',
        findId,
        '일정 관리 정보',
      );
    }

    const date = new Date();
    this.$store.commit('setMonthToMainCalendar', yyyymmdd(date).substr(5, 2));
    this.$store.commit('setYearToMainCalendar', yyyymmdd(date).substr(0, 4));

    await this.FETCH_WITH_PAYLOAD(
      'FETCH_MAIN_CALENDAR_MONTH',
      `${this.year}-${this.month}`,
      '일정',
    );
    let events = this.filterData;
    function weekday(dateStr) {
      const week = ['일', '월', '화', '수', '목', '금', '토'];
      let dayOfWeek = week[new Date(dateStr).getDay()];
      return dayOfWeek;
    }

    // await this.FETCH_WITH_PAYLOAD('FETCH_HOLIDAY', this.year, '공휴일');

    let calendarEl = document.getElementById('calendar');
    this.calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, InteractionPlugin],
      height: 'auto',
      contentHeight: '100%',
      expandRows: true,
      slotMinTime: '08:00',
      slotMaxTime: '22:00',
      stickyHeaderDates: false,
      headerToolbar: {
        left: 'title prev,next',
        center: '',
        right: '',
      },
      titleFormat: date => {
        return `${date.date.year}년 ${date.date.month + 1}월`;
      },
      fixedWeekCount: false,
      initialView: 'dayGridMonth',
      editable: true,
      selectable: true,
      nowIndicator: true,
      dayMaxEvents: 3, // allow "more" link when too many events
      moreLinkContent: arg => {
        arg.text = `+${arg.num}`;
      },
      dateClick: info => {
        let dayOfWeek = weekday(info.dateStr);
        this.dateString = `${info.date.getMonth() +
          1}월 ${info.date.getDate()}일 ${dayOfWeek}요일`;
        this.dateString2 = info.dateStr;
        this.isCalendarModalOpen = true;
      },
      eventClick: info => {
        let dateStr = info.event.start;
        let dayOfWeek = weekday(dateStr);
        this.dateString = `${dateStr.getMonth() +
          1}월 ${dateStr.getDate()}일 ${dayOfWeek}요일`;
        this.dateString2 = yyyymmdd(dateStr);
        this.openModal();
      },
      events,
      customButtons: {
        prev: {
          // this overrides the prev button
          text: 'PREV',
          click: async () => {
            this.calendar.prev();

            let prevDate = '';
            let year = this.calendar.getDate().getFullYear();
            let month = this.calendar.getDate().getMonth();
            if (month > 9) {
              prevDate = `${year}-${month + 1}`;
            } else {
              prevDate = `${year}-0${month + 1}`;
            }
            this.$store.commit('setMonthToMainCalendar', prevDate.substr(5, 2));
            this.$store.commit('setYearToMainCalendar', prevDate.substr(0, 4));
            await this.FETCH_WITH_PAYLOAD(
              'FETCH_MAIN_CALENDAR_MONTH',
              prevDate,
              '일정',
            );

            this.calendar.batchRendering(() => {
              // remove all events
              this.calendar.getEvents().forEach(event => event.remove());
              // add your new events source
              this.calendar.addEventSource(this.filterData);
            });
          },
        },
        next: {
          // this overrides the next button
          text: 'NEXT',
          click: async () => {
            this.calendar.next();
            let nextDate = '';
            let year = this.calendar.getDate().getFullYear();
            let month = this.calendar.getDate().getMonth();
            if (month > 9) {
              nextDate = `${year}-${month + 1}`;
            } else {
              nextDate = `${year}-0${month + 1}`;
            }
            this.$store.commit('setMonthToMainCalendar', nextDate.substr(5, 2));
            this.$store.commit('setYearToMainCalendar', nextDate.substr(0, 4));
            await this.FETCH_WITH_PAYLOAD(
              'FETCH_MAIN_CALENDAR_MONTH',
              nextDate,
              '일정',
            );
            this.calendar.batchRendering(() => {
              // remove all events
              this.calendar.getEvents().forEach(event => event.remove());
              // add your new events source
              this.calendar.addEventSource(this.filterData);
            });
          },
        },
      },
      eventDidMount: function(info) {
        let dotEl = info.el.getElementsByClassName('fc-daygrid-event-dot')[0];
        let status = info.event.extendedProps.status;
        switch (status) {
          case 1:
            dotEl.style.borderColor = '#b960eb'; //수주
            break;
          case 2:
            dotEl.style.borderColor = '#e8d311'; //원자재 발주
            break;
          case 3:
            dotEl.style.borderColor = '#3fb516'; //원자재 입고 예정일
            break;
          case 4:
            dotEl.style.borderColor = '#dc51a5'; //원자재 입고
            break;
          case 5:
            dotEl.style.borderColor = '#eb7860'; //작업지시
            break;
          case 6:
            dotEl.style.borderColor = '#608aeb'; //작업완료 예정일
            break;
          case 7:
            dotEl.style.borderColor = '#fc5634'; //생산완료
            break;
          case 8:
            dotEl.style.borderColor = '#33d1b6'; //납품 예정일
            break;
          case 9:
            dotEl.style.borderColor = '#b8f52a'; //납품일
            break;
          case 10:
            dotEl.style.borderColor = '#0112ff'; //주간 생산 리포트
            break;
          case 11:
            dotEl.style.borderColor = '#ff8fc7'; //월간 생산 리포트
            break;
          default:
            dotEl.style.borderColor = '#1d1d1d'; //관리자 등록
        }
      },
      dayCellDidMount: async arg => {
        if (this.holiday != undefined) {
          let dayNumEl = arg.el.querySelector(
            '.fc-daygrid-day-top .fc-daygrid-day-number',
          );
          let spanEl = document.createElement('span');
          let argDate = yyyymmdd(arg.date).replace(/-/gi, '');
          this.holiday.forEach(element => {
            if (argDate == String(element.locdate)) {
              spanEl.innerHTML = element.dateName;
              dayNumEl.className += ' holiday';
              dayNumEl.append(spanEl);
            }
          });
        }
      },
    });
    this.calendar.render();
  },
};
</script>

<style scoped></style>
