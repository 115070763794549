<template>
  <div class="modalPopup" id="notice_modal">
    <div class="modal_header">
      <h3 class="title">{{ notice.title }}</h3>
      <button class="modal_close" @click="ModalClose"></button>
      <div class="writer">
        <span class="writer_txt"
          >작성자 <span>{{ notice.user_name }}</span></span
        >
        <span class="writer_txt"
          >작성일 <span>{{ notice.create_time | formatDate }}</span></span
        >
        <span class="writer_txt"
          >만료일 <span>{{ notice.expired_day || '' }}</span></span
        >
      </div>
    </div>
    <div class="modal_body">
      <div class="notice_contents">
        {{ notice.detail }}
      </div>
    </div>
    <div class="modal_footer">
      <button class="btn_sub2" @click="ModalClose">
        확인
      </button>
    </div>
  </div>
</template>

<script>
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';

export default {
  mixins: [DRAG_MODAL_MIXIN],
  props: {
    notice: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ModalClose() {
      this.$emit('onclose');
    },
  },
};
</script>

<style scoped></style>
