<template>
  <div class="frequently_menu card">
    <div class="card_head">
      <h4 class="subject">
        자주 사용하는 메뉴<span>{{
          `(${date.start.replace(/-/gi, '.').substr(5, 5)}~${date.end
            .replace(/-/gi, '.')
            .substr(5, 5)})`
        }}</span>
      </h4>
    </div>
    <div class="card_body">
      <div
        v-for="(frequently_menu, index) in filteredTopSix"
        :key="frequently_menu.menu_group_id"
      >
        <div class="rank">
          <span class="rank_txt"
            ><b>{{ index + 1 }}</b
            >위</span
          >
          <span class="category"
            >{{ getTopMenuNameFromMenuId(frequently_menu.menu_group_id) }} >
            <router-link
              :to="
                openedMenus.length < 22
                  ? getSubMenuPathFromMenuId(frequently_menu.menu_group_id)
                  : openedMenus.find(
                      x =>
                        x.path ==
                        getSubMenuPathFromMenuId(frequently_menu.menu_group_id),
                    )
                  ? getSubMenuPathFromMenuId(frequently_menu.menu_group_id)
                  : ''
              "
            >
              {{ getSubMenuNameFromMenuId(frequently_menu.menu_group_id) }}
            </router-link></span
          >
        </div>
        <div class="progress">
          <transition appear name="fade">
            <span
              v-show="show"
              @mousemove="mouseEvent($event, index)"
              :data-progress="getPercent(frequently_menu.count)"
              :style="`width : ${getPercent(frequently_menu.count)}%`"
            >
              <small :style="`left: ${pageX - progressX - 28}px;`">{{
                `${frequently_menu.count}회`
              }}</small></span
            >
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalMixin from '@/mixins/modal.js';
import FetchMixin from '@/mixins/fetch';
import SpinnerMixin from '@/mixins/spinner.js';
import { formatDateNoHours } from '@/utils/filters';

export default {
  mixins: [ModalMixin, SpinnerMixin, FetchMixin],
  data() {
    return {
      show: false,
      countVisible: false,
      date: {
        start: undefined,
        end: undefined,
      },
      pageX: null,
      progressX: null,
      targetIndex: null,
    };
  },
  computed: {
    ...mapGetters({
      // group_actions: 'getUserActionGroupByMenuGroupIdTopSix',
      group_actions: 'getUserActionGroupByMenuGroupId',
      openedMenus: 'getOpenedMenus',
      menuItems: 'getMenuItems',
      userId: 'getUserId',
      users: 'getUsersTempFromUserPage',
      filterCheck: 'getFilterCheckFromAuthority',
      userActions: 'getUserAction',
    }),
    filteredTopSix() {
      if (this.filterCheck.length != 0) {
        let topSixList = this.lodash.clonedeep(this.group_actions);
        let permissionChk = this.lodash
          .clonedeep(this.filterCheck)
          .map(x => x.sub_menu)
          .reduce((a, b) => a.concat(b));

        topSixList = topSixList.filter(
          x =>
            permissionChk.find(
              y => y.id == x.menu_group_id && y.permission != 0,
            ) != undefined,
        );

        return topSixList.splice(0, 6);
      } else return [];
    },
  },
  methods: {
    mouseEvent($event, index) {
      let progress = document.querySelectorAll('.progress span');
      this.progressX = progress[index].getBoundingClientRect().left;
      this.pageX = $event.pageX;
      this.targetIndex = index;
    },
    resetDate() {
      const today = new Date();
      this.date.end = formatDateNoHours(today);
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      this.date.start = formatDateNoHours(lastMonth);
    },
    async fetch_group_action() {
      await this.$store
        .dispatch('FETCH_USER_ACTION', this.date)
        .then(() => {
          return 'success';
        })
        .catch(() => {
          this.openOneButtonModal(
            '불러오기 오류',
            '자주 사용하는 메뉴 불러오는 중 오류발생. 다시 로그인하여주십시오.',
          );
          return 'failed';
        });
    },
    getTopMenuNameFromMenuId(id) {
      try {
        return this.menuItems.find(x => x.sub_menu.find(y => y.id == id)) !=
          undefined
          ? this.menuItems.find(x => x.sub_menu.find(y => y.id == id)).name
          : '';
      } catch (error) {
        console.log(error);
        return '';
      }
    },
    getSubMenuNameFromMenuId(id) {
      try {
        return this.menuItems.find(x => x.sub_menu.find(y => y.id == id)) !=
          undefined
          ? this.menuItems
              .find(x => x.sub_menu.find(y => y.id == id))
              .sub_menu.find(x => x.id == id).name
          : '';
      } catch (error) {
        console.log(error);
        return '';
      }
    },
    getSubMenuPathFromMenuId(id) {
      return this.menuItems.find(x => x.sub_menu.find(y => y.id == id)) !=
        undefined
        ? this.menuItems
            .find(x => x.sub_menu.find(y => y.id == id))
            .sub_menu.find(x => x.id == id).sub_detail
        : '';
    },
    getPercent(count) {
      let sum = 0;
      this.group_actions.forEach(element => {
        if (sum < element.count) {
          sum = element.count;
        }
      });
      return (count / sum) * 100;
    },
  },
  async beforeMount() {},
  async created() {
    await this.resetDate();
    await this.fetch_group_action();

    if (this.menuItems.length == 0) {
      const findId = this.users.find(x => x.account == this.userId);
      if (findId.user_role_type_id == 1) {
        this.showSpinner();
        await this.$store
          .dispatch('FETCH_ALL_MENUS', this.userId)
          .then(() => {})
          .catch(error => {
            console.log('error', error);
            this.openOneButtonModal(
              '메뉴 로드 중 오류',
              '사용자 메뉴 리스트를 불러오는 중 오류 발생. 다시 로그인하여주십시오.',
            );
          });
      } else {
        this.showSpinner();
        await this.$store
          .dispatch('FETCH_ALL_USER_MENUS', findId.id)
          .then(response => {
            console.log('RESPONSE :: ', response);
          })
          .catch(error => {
            console.log('ERROR :: ', error);
            this.openOneButtonModal(
              '메뉴 로드 중 오류',
              '사용자 메뉴 리스트를 불러오는 중 오류 발생. 다시 로그인하여주십시오.',
            );
          });
      }
      this.hideSpinner();
    }
    const findId = this.users.find(x => x.account == this.userId).id;
    if (this.filterCheck.length == 0) {
      this.FETCH_WITH_PAYLOAD('FETCH_USER_AUTH', findId, '사용자 메뉴 권한');
    }

    this.show = false;
    this.show = true;
    // console.log(this.group_actions);
  },
};
</script>

<style scoped>
/* .progress {
  overflow: hidden;
} */

.fade-enter-active {
  transition: all 0.6s ease;
}
.fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter, .fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-100px);
  opacity: 0;
}
</style>
