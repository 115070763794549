<template>
  <div
    class="modalPopup notice_more_modal"
    v-if="modalClose"
    @keyup.self.esc="isModalClose"
  >
    <div class="modal_header">
      <h3 class="title">공지사항</h3>
      <button class="modal_close" @click="isModalClose"></button>
    </div>
    <div class="modal_body">
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>제목</th>
              <th>작성자</th>
              <th>작성일</th>
              <th>만료일</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(notice, index) in sortedNoticeList"
              :key="notice.id"
              @click="selectedIndex = index"
              :class="{ active: selectedIndex == index }"
              @focus="selectedIndex = index"
              tabindex="0"
            >
              <td>{{ notice.title }}</td>
              <td>{{ findInfoFromId(users, notice.user_id).name }}</td>
              <td>{{ notice.create_time.substr(0, 10) }}</td>
              <td>{{ notice.expired_day || '' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="notice_info" :class="{ active: selectedIndex !== -1 }">
        <div class="input_text">
          <label>제목</label>
          <input
            type="text"
            :placeholder="
              selectedIndex == -1
                ? '제목'
                : sortedNoticeList[selectedIndex].title
            "
            disabled
          />
        </div>
        <div class="input_text">
          <label>내용</label>
          <textarea
            :placeholder="
              selectedIndex == -1
                ? '내용'
                : sortedNoticeList[selectedIndex].detail
            "
            disabled
          ></textarea>
        </div>
      </div>
    </div>
    <div class="modal_footer">
      <button class="btn_sub2" @click="isModalClose">
        확인
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';

export default {
  mixins: [FETCH_MIXIN, DRAG_MODAL_MIXIN],
  data() {
    return {
      modalClose: true,
      isNoticeModalOpen: false,
      selectedIndex: -1,
      selectedNotice: {},
    };
  },
  computed: {
    ...mapGetters({
      noticeAllList: 'getNotExpiredNoticeList',
      users: 'getUsersFromUserPage',
      userId: 'getUserId',
      user_role_type: 'getUserRoleTypeFromUserPage',
    }),
    sortedNoticeList() {
      if (this.noticeAllList == undefined || this.noticeAllList.length < 1) {
        return [];
      } else {
        const newNoticeListTemp = this.lodash.clonedeep(this.noticeAllList);
        return newNoticeListTemp.sort((a, b) => b.id - a.id);
      }
    },
  },
  methods: {
    isModalClose() {
      this.$emit('onclose');
    },
    openModal() {
      this.isNoticeModalOpen = true;
    },
    closeModal() {
      this.isNoticeModalOpen = false;
      this.selectedNotice = {};
    },
    async fetch_notice_list() {
      try {
        this.showSpinner();
        await this.$store
          .dispatch('FETCH_NOTICE_LIST_ONLY_NOT_EXPIRED')
          .then(() => {})
          .catch(() => {
            this.openOneButtonModal(
              '불러오기 오류',
              '공지사항 리스트를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
            );
          });
        this.hideSpinner();
      } catch (error) {
        this.openOneButtonModal(
          '불러오기 오류',
          '공지사항 리스트를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
        );
        this.hideSpinner();
      }
    },
  },
  async created() {
    await this.fetch_notice_list();
  },
};
</script>

<style scoped></style>
