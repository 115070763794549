<template>
  <div>
    <div
      class="modalPopup patch_note_more_modal"
      @keyup.self.esc="isModalClose"
    >
      <div class="modal_header">
        <h3 class="title">업데이트</h3>
        <button class="modal_close" @click="isModalClose"></button>
      </div>
      <div class="modal_body">
        <div class="patch_note_title">
          <div>
            <p>
              {{ patch_note.title }}
            </p>
            <p class="note_date">
              게시일 :
              {{ patch_note.create_time | formatDateNoHours }}
            </p>
          </div>
          <Viewer
            class="patch_note_contents"
            :key="key"
            v-show="patch_note.content != undefined"
            :initialValue="patch_note.content"
          />
        </div>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <thead>
              <tr>
                <th>번호</th>
                <th>작성일</th>
                <th>제목</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(patchNote, index) in patchNoteOnlyList"
                :key="index"
                @click="selectRow(index)"
                :class="{ active: selectedIndex == index }"
                @focus="selectRow(index)"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ patchNote.create_time | formatDateNoHours }}</td>
                <td>{{ patchNote.title }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal_footer">
        <button class="btn_sub2" @click="isModalClose">
          확인
        </button>
      </div>
    </div>
    <div class="modal_layer"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import SPINNER_MIXIN from '@/mixins/spinner';
import MODAL_MIXIN from '@/mixins/modal';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import Viewer from '@/layouts/components/Viewer.vue';

export default {
  mixins: [DRAG_MODAL_MIXIN, FETCH_MIXIN, SPINNER_MIXIN, MODAL_MIXIN],
  components: {
    Viewer,
  },
  data() {
    return {
      selectedIndex: 0,
      patch_note: null,
      key: 0,
    };
  },
  computed: {
    ...mapGetters({
      newPatchNote: 'getNewPatchNoteFromPatchNote',
      patchNoteOnlyList: 'getPatchNoteOnlyListFromPatchNote',
    }),
  },
  methods: {
    async selectRow(index) {
      this.selectedIndex = index;
      this.showSpinner();
      await this.$store
        .dispatch(
          'FETCH_PATCH_NOTE_SELECT_ONE',
          this.patchNoteOnlyList[index].id,
        )
        .then(response => {
          this.patch_note = response.data.data_list[0];
          this.key++;
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '패치노트 불러오는 중 오류발생.',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    isModalClose() {
      this.$emit('onclose');
    },
  },
  created() {
    if (this.newPatchNote != null) {
      this.patch_note = this.lodash.clonedeep(this.newPatchNote);
    }
  },
};
</script>

<style scoped></style>
