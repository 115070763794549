var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"contents"}},[_c('div',{staticClass:"main_section"},[_c('div',{staticClass:"quick_menu"},[_c('ul',[_c('li',[(_vm.checkedPermission('/code/bom'))?_c('router-link',{attrs:{"to":"/code/bom"}},[_c('img',{attrs:{"src":require("@/assets/images/icon/m-icon-bom.png"),"alt":""}}),_c('span',[_vm._v("BOM 관리")])]):_vm._e()],1),(_vm.checkedPermission('/plan/sales'))?_c('li',[_c('router-link',{attrs:{"to":"/plan/sales"}},[_c('img',{attrs:{"src":require("@/assets/images/icon/m-icon-product.png"),"alt":""}}),_c('span',[_vm._v("제품수주 관리")])])],1):_vm._e(),(_vm.checkedPermission('/plan/cal'))?_c('li',[_c('router-link',{attrs:{"to":"/plan/cal"}},[_c('img',{attrs:{"src":require("@/assets/images/icon/m-icon-material.png"),"alt":""}}),_c('span',[_vm._v("원자재 소요량 산출")])])],1):_vm._e(),(_vm.checkedPermission('/material/input'))?_c('li',[_c('router-link',{attrs:{"to":"/material/input"}},[_c('img',{attrs:{"src":require("@/assets/images/icon/m-icon-on.png"),"alt":""}}),_c('span',[_vm._v("원자재 입고 등록")])])],1):_vm._e(),(
            _vm.checkedPermission('/process/workorder') ||
              _vm.checkedPermission('/custom/process/workorder')
          )?_c('li',[_c('router-link',{attrs:{"to":_vm.subMenus.length > 0 &&
              _vm.subMenus.find(function (x) { return x.sub_detail == '/process/workorder'; }) ==
                undefined
                ? '/custom/process/workorder'
                : '/process/workorder'}},[_c('img',{attrs:{"src":require("@/assets/images/icon/m-icon-owner.png"),"alt":""}}),_c('span',[_vm._v("작업지시서 관리")])])],1):_vm._e(),(_vm.checkedPermission('/process/production'))?_c('li',[_c('router-link',{attrs:{"to":"/process/production"}},[_c('img',{attrs:{"src":require("@/assets/images/icon/m-icon-factory.png"),"alt":""}}),_c('span',[_vm._v("생산공정 진행")])])],1):_vm._e(),(_vm.checkedPermission('/quality/report'))?_c('li',[_c('router-link',{attrs:{"to":"/quality/report"}},[_c('img',{attrs:{"src":require("@/assets/images/icon/m-icon-report.png"),"alt":""}}),_c('span',[_vm._v("품질분석 리포트")])])],1):_vm._e(),(_vm.checkedPermission('/system/user'))?_c('li',[_c('router-link',{attrs:{"to":"/system/user"}},[_c('img',{attrs:{"src":require("@/assets/images/icon/m-icon-user.png"),"alt":""}}),_c('span',[_vm._v("사용자 관리")])])],1):_vm._e()])]),_c('frequently-menu'),(_vm.patchNoteOnlyList.length > 0)?_c('patch-note-card',{on:{"popPatchNote":function($event){return _vm.toggleData(true)}}}):_vm._e(),_c('notice-card'),(_vm.patchModlaOpen && _vm.newPatchNote != null)?_c('patch-note-modal',{attrs:{"clickPatchNote":_vm.clickPatchNote},on:{"onclose":function($event){return _vm.toggleData(false)}}}):_vm._e()],1),_c('full-calendar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }