<template>
  <div class="notice card">
    <div class="card_head">
      <li v-if="checkedPermission('/system/notice')">
        <h4>
          <router-link to="/system/notice" class="subject">
            공지사항
          </router-link>
        </h4>
      </li>
      <button class="more_btn" @click="openMoreModal">
        더보기
      </button>
    </div>
    <div class="card_body">
      <table>
        <tbody v-for="(notice, index) in notices" :key="notice.id">
          <tr>
            <td class="subject">
              <a @click="OpenPopup(index)">
                {{ notice.title }}
              </a>
            </td>
            <td class="writer">{{ getUserName(notice.user_id) }}</td>
            <td class="date">{{ notice.create_time | formatDateNoHours }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <notice-modal
      :notice="selectedNotice"
      @onclose="ClosePopup"
      v-if="isNoticeModalOpen"
    ></notice-modal>
    <notice-more-modal
      v-if="isNoticeMoreModalOpen"
      @onclose="ClosePopup"
    ></notice-more-modal>
    <div
      class="modal_layer"
      v-if="isNoticeModalOpen || isNoticeMoreModalOpen"
      @click="ClosePopup"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import NoticeModal from '@/layouts/components/NoticeModal.vue';
import NoticeMoreModal from '@/layouts/components/NoticeMoreModal.vue';
import FavoriteMixin from '@/mixins/favorite';

export default {
  mixins: [ModalMixin, SpinnerMixin, FavoriteMixin],
  components: {
    NoticeModal,
    NoticeMoreModal,
  },
  data() {
    return {
      isNoticeModalOpen: false,
      isNoticeMoreModalOpen: false,
      selectedNotice: {},
    };
  },
  computed: {
    ...mapGetters({
      notices: 'getNoticeListTop3',
      users: 'getUsersTempFromUserPage',
    }),
  },
  methods: {
    OpenPopup(index) {
      console.log(this.notices);
      this.selectedNotice = {
        user_id: this.notices[index].user_id,
        user_name: this.getUserName(this.notices[index].user_id),
        create_time: this.notices[index].create_time,
        title: this.notices[index].title,
        detail: this.notices[index].detail,
        expired_day: this.notices[index].expired_day,
      };
      this.isNoticeModalOpen = true;
    },
    ClosePopup() {
      this.isNoticeModalOpen ? (this.isNoticeModalOpen = false) : '';
      this.isNoticeMoreModalOpen ? (this.isNoticeMoreModalOpen = false) : '';
    },
    openMoreModal() {
      this.isNoticeMoreModalOpen = true;
    },
    async fetch_notice_list() {
      try {
        this.showSpinner();
        await this.$store
          .dispatch('FETCH_NOTICE_LIST_ONLY_NOT_EXPIRED')
          .then(() => {})
          .catch(() => {
            this.openOneButtonModal(
              '불러오기 오류',
              '공지사항 리스트를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
            );
          });
        this.hideSpinner();
      } catch (error) {
        this.openOneButtonModal(
          '불러오기 오류',
          '공지사항 리스트를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
        );
        this.hideSpinner();
      }
    },
    async fetch_user_list() {
      try {
        this.showSpinner();
        console.log(5);
        await this.$store
          .dispatch('FETCH_USER_LIST')
          .then(() => {})
          .catch(() => {
            this.openOneButtonModal(
              '불러오기 오류',
              '사용자 정보를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
            );
          });
        this.hideSpinner();
      } catch (error) {
        this.openOneButtonModal(
          '불러오기 오류',
          '사용자 정보를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
        );
        this.hideSpinner();
      }
    },
    getUserName(id) {
      const element = this.users.find(x => x.id == id);
      if (element != null || element != undefined) {
        return element.name;
      } else {
        return '';
      }
    },
  },
  async created() {
    await this.fetch_notice_list();

    // if (this.users.length < 1) {
    //   await this.fetch_user_list();
    // }
  },
};
</script>

<style scoped></style>
