<template>
  <div id="contents">
    <div class="main_section">
      <div class="quick_menu">
        <ul>
          <li>
            <router-link v-if="checkedPermission('/code/bom')" to="/code/bom">
              <img src="@/assets/images/icon/m-icon-bom.png" alt="" />
              <span>BOM 관리</span>
            </router-link>
          </li>
          <li v-if="checkedPermission('/plan/sales')">
            <router-link to="/plan/sales">
              <img src="@/assets/images/icon/m-icon-product.png" alt="" />
              <span>제품수주 관리</span>
            </router-link>
          </li>
          <li v-if="checkedPermission('/plan/cal')">
            <router-link to="/plan/cal">
              <img src="@/assets/images/icon/m-icon-material.png" alt="" />
              <span>원자재 소요량 산출</span>
            </router-link>
          </li>
          <li v-if="checkedPermission('/material/input')">
            <router-link to="/material/input">
              <img src="@/assets/images/icon/m-icon-on.png" alt="" />
              <span>원자재 입고 등록</span>
            </router-link>
          </li>
          <li
            v-if="
              checkedPermission('/process/workorder') ||
                checkedPermission('/custom/process/workorder')
            "
          >
            <router-link
              :to="
                subMenus.length > 0 &&
                subMenus.find(x => x.sub_detail == '/process/workorder') ==
                  undefined
                  ? '/custom/process/workorder'
                  : '/process/workorder'
              "
            >
              <img src="@/assets/images/icon/m-icon-owner.png" alt="" />
              <span>작업지시서 관리</span>
            </router-link>
          </li>
          <li v-if="checkedPermission('/process/production')">
            <router-link to="/process/production">
              <img src="@/assets/images/icon/m-icon-factory.png" alt="" />
              <span>생산공정 진행</span>
            </router-link>
          </li>
          <li v-if="checkedPermission('/quality/report')">
            <router-link to="/quality/report">
              <img src="@/assets/images/icon/m-icon-report.png" alt="" />
              <span>품질분석 리포트</span>
            </router-link>
          </li>
          <li v-if="checkedPermission('/system/user')">
            <router-link to="/system/user">
              <img src="@/assets/images/icon/m-icon-user.png" alt="" />
              <span>사용자 관리</span>
            </router-link>
          </li>
        </ul>
      </div>
      <frequently-menu></frequently-menu>
      <patch-note-card
        v-if="patchNoteOnlyList.length > 0"
        @popPatchNote="toggleData(true)"
      ></patch-note-card>
      <notice-card></notice-card>
      <patch-note-modal
        v-if="patchModlaOpen && newPatchNote != null"
        :clickPatchNote="clickPatchNote"
        @onclose="toggleData(false)"
      ></patch-note-modal>
    </div>
    <full-calendar></full-calendar>
  </div>
</template>

<script>
import FrequentlyMenu from '@/layouts/components/FrequentlyMenu.vue';
import FullCalendar from '@/layouts/components/FullCalendar.vue';
import NoticeCard from '@/layouts/components/NoticeCard.vue';
import PatchNoteCard from '@/layouts/components/PatchNoteCard.vue';
import PatchNoteModal from '@/layouts/components/PatchNoteModal.vue';
import FAVORITE_MIXIN from '@/mixins/favorite';
import { mapActions, mapGetters } from 'vuex';

export default {
  mixins: [FAVORITE_MIXIN],
  components: {
    FullCalendar,
    PatchNoteCard,
    FrequentlyMenu,
    NoticeCard,
    PatchNoteModal,
  },
  data() {
    return {
      patchModlaOpen: false,
      clickPatchNote: false,
    };
  },
  computed: {
    ...mapGetters({
      getMenuItems: 'getMenuItems',
      getUserId: 'getUserId',
      month: 'getMonthFromCalendar',
      year: 'getYearFromCalendar',

      // PatchNote
      newPatchNote: 'getNewPatchNoteFromPatchNote',
      patchNoteOnlyList: 'getPatchNoteOnlyListFromPatchNote',
      systemCompany: 'getSystemCompany',
      replaceList: 'getReplaceListFrompartsReplaeManagementForm',
      machines: 'getMachines',
      partsList: 'getPartsListFrompartsReplaeManagementForm',
    }),
    subMenus() {
      if (this.getMenuItems.length > 0) {
        const subMenus = this.getMenuItems
          .map(x => x.sub_menu)
          .reduce((a, b) => a.concat(b));
        return subMenus;
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions(['TestGetApi', 'TestPutApi', 'TestPostApi', 'TestDeleteApi']),

    async testPutApi() {
      const payloadTest = {
        detail: '/system/useraction',
        name: '사용현황 리포트',
        id: 118,
      };
      const response = await this.TestPutApi(payloadTest);
      console.log(response);
    },
    async testGetApi(payload) {
      const response = await this.TestGetApi(payload);
      console.log(response);
    },
    async testPostApi() {
      const payload = {
        store_id: 1,
        name: '위치A',
        detail: '위치A설명',
      };
      const response = await this.TestPostApi(payload);
      console.log(response);
    },
    async testDeleteApi(payload) {
      const response = await this.TestDeleteApi(payload);
      console.log(response);
    },
    toggleData(chk) {
      this.patchModlaOpen = this.clickPatchNote = chk;
    },
  },
  async created() {
    if (this.machines.length == 0 && this.systemCompany.basic == false) {
      await this.$store.dispatch('FETCH_MACHINE');
    }
    if (this.partsList.length == 0 && this.systemCompany.basic == false) {
      await this.$store.dispatch('FETCH_PARTS');
    }
    if (this.replaceList.length == 0 && this.systemCompany.basic == false) {
      await this.$store.dispatch('FETCH_PARTS_REPLACE', this.machines);
    }
    await this.$store.commit(
      'setFilteredReplaceListToReplaceMnanagementPage',
      this.replaceList.filter(x => x.life_time != null),
    );
    // for (let i = 0; i < this.replaceList.length; i++) {
    //   let change_dt = new Date(this.replaceList[i].change_dt);
    //   let date = new Date(Date.now());
    //   if (
    //     (
    //       this.replaceList[i].life_time -
    //       (date.getTime() - change_dt.getTime()) / (1000 * 60 * 60)
    //     ).toFixed(0) <= 24 &&
    //     this.replaceList[i].life_time != null &&
    //     this.replaceList[i].change_dt != null
    //   ) {
    //     alert('교체해야하는 항목있음 : ' + JSON.stringify(this.replaceList[i]));
    //   }
    // }
    if (this.systemCompany.length > 0) {
      await this.FETCH('FETCH_SYSTEM_COMPANY', '업체정보');
    }
    await this.FETCH('FETCH_PATCH_NOTE_LIST', '패치노트 목록');

    if (this.patchNoteOnlyList.length > 0) {
      const newPatchNote = this.patchNoteOnlyList[0];
      if (newPatchNote.id != undefined || newPatchNote.id != null) {
        await this.FETCH_WITH_PAYLOAD(
          'FETCH_PATCH_NOTE_NEW_ONE',
          newPatchNote.id,
          '최신 패치노트',
        );
      }
    }
    const lastPatchNoteId = localStorage.getItem('patch_note_id');

    if (
      this.newPatchNote != null &&
      (lastPatchNoteId == undefined || lastPatchNoteId != this.newPatchNote.id)
    ) {
      this.patchModlaOpen = true;
    }
  },
};
</script>

<style scoped></style>
